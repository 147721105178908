import * as React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import PrintIcon from '@mui/icons-material/Print';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { accessTypeCustom } from 'shared/constants';
import { IconButton } from '@mui/material';

const lockedColor = '#fd5c63';

export default ({ access, onChange }) => {
  const lock = (access & accessTypeCustom.Lock) === accessTypeCustom.Lock;
  const lockPrint = (access & accessTypeCustom.LockPrint) === accessTypeCustom.LockPrint;
  const lockCopy = (access & accessTypeCustom.LockCopy) === accessTypeCustom.LockCopy;
  const lockDownload = (access & accessTypeCustom.LockDownload) === accessTypeCustom.LockDownload; 

  return (
    <>
      <IconButton title='Блокировка' onClick={() => onChange(accessTypeCustom.Lock)}>
        <LockIcon sx={{ fill: lock ? lockedColor : 'GrayText' }} />
      </IconButton>
      <IconButton title='Блокировка Печати' onClick={() => onChange(accessTypeCustom.LockPrint)}>
        <PrintIcon sx={{ fill: lockPrint ? lockedColor : 'GrayText' }} />
      </IconButton>
      <IconButton title='Блокировка Копирования' onClick={() => onChange(accessTypeCustom.LockCopy)}>
        <FileCopyIcon sx={{ fill: lockCopy ? lockedColor : 'GrayText' }} />
      </IconButton>
      <IconButton title='Блокировка Скачивания' onClick={() => onChange(accessTypeCustom.LockDownload)}>
        <FileDownloadIcon sx={{ fill: lockDownload ? lockedColor : 'GrayText' }} />
      </IconButton>
    </>
  );
};